﻿<script lang="ts">
    import type {Scenario} from 'Generated/RestClient.g';
    import clickOutside from 'Lib/Utils/ClickOutside';
    import {Row} from 'Components/UI';
    import ActionButton from 'Pages/PublicationPage/Components/ActionButton.svelte';
    import translations from 'Assets/i18n';

    const translationContext = translations.pages.publication.customLinksListView.searchBox;

    export let scenarios: Scenario[];
    export let selectedScenarioId: string | undefined;

    let value = '';
    let visible = false;
    $: updateTextField(selectedScenarioId);

    function updateTextField(guid?: string) {
        if (!guid) {
            value = '';
            return;
        }

        const scenario = scenarios.find(s => s.id === guid);
        if (scenario) {
            value = scenario.name;
            return;
        }

        value = translationContext.unknownPresentation;
    }

    function select(scenario: Scenario) {
        selectedScenarioId = scenario.id;
        visible = false;
    }

    function handleFocusLoss() {
        visible = false;
        updateTextField(selectedScenarioId);
    }
</script>

<div class="root" use:clickOutside={handleFocusLoss}>
    <Row>
        <input type="text" bind:value={value} on:focus={()=>visible=true} placeholder={translationContext.placeholder}>
        {#if selectedScenarioId}
            <ActionButton tooltipContent={translationContext.clearTooltip}
                          on:click={() => selectedScenarioId = undefined}>
                <span class="mdi mdi-close"></span>
            </ActionButton>
        {/if}
    </Row>
    {#if visible}
        <div class="dropdown">
            {#each scenarios as scenario}
                <button on:click|preventDefault={()=>select(scenario)}>{scenario.name}</button>
            {/each}
            {#if scenarios.length === 0}
                <div class="placeholder">{translationContext.noPresentations}</div>
            {/if}
        </div>
    {/if}
</div>

<style>
    .root {
        position: relative;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.53);
    }

    input {
        width: 100%;
        font-size: 16px;
        padding: .5rem;
        border: none;
    }

    .dropdown {
        width: 100%;
        max-height: 320px;
        overflow: auto;
        position: absolute;
        background-color: white;
        box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.5);
        user-select: none;
        display: flex;
        flex-direction: column;
        padding: 2px;
    }

    button {
        background: none;
        text-align: left;
        font-size: 16px;
        font-weight: unset;
        border: none;
    }

    .dropdown button {
        padding: .5rem;
        cursor: pointer;
        transition: .2s;
    }

    .dropdown .placeholder {
        padding: .5rem;
        font-size: 16px;
    }

    .dropdown button:hover {
        background-color: #addbff;
    }

    .mdi {
        font-size: 18px;
        padding: 0 .3rem;
    }
</style>
