﻿<script lang="ts">
    import Details from 'Pages/PublicationPage/Components/Table/Details.svelte';
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import type {ComponentType} from 'svelte';

    export let item: any;
    export let columns: Map<ColumnKey, ColumnOptions>;
    export let buttons: ComponentType;
</script>

<tr>
    {#each columns as [key, options]}
        <td class:primary={options.primary} style="{options.width ? `width:${options.width};` : ''}">
            {#if options.name}
                <span class="label">{options.name}</span>
            {/if}
            {#if key === 'buttons'}
                <svelte:component this={buttons} {item}/>
            {:else if options.component}
                <svelte:component this={options.component} {item}/>
            {:else if options.details}
                <Details {options} {item} {key}/>
            {:else if options.html}
                {@html options.formatter?.(item[options.key]) ?? item[options.key]}
            {:else }
                {options.formatter?.(item[options.key]) ?? item[options.key]}
            {/if}
        </td>
    {/each}
</tr>
