﻿<script lang="ts">
    import ActionButton from 'Pages/PublicationPage/Components/ActionButton.svelte';
    import {Row} from 'Components/UI';
    import PopupMenu from 'Pages/PublicationPage/Components/PopupMenu.svelte';
    import translations from 'Assets/i18n';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import {Scenario, ScenarioWithFiles} from 'Generated/RestClient.g';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import {applicationContextKey} from 'PublicationDependencies';
    import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';

    const translationContext = translations.pages.publication.presentationListView;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const publicationListService = applicationContext.get(PublicationListService);
    const {
        scenarioIdForPasswordDialogObservable,
        deletableScenarioObservable
    } = applicationContext.get(PublicationListContext);

    export let disabled = false;
    export let item: Nullable<ScenarioWithFiles> = undefined;

    let shareMenuVisible = false;

    $: scenario = item as ScenarioWithFiles;

    async function openPasswordDialog(id: string) {
        $scenarioIdForPasswordDialogObservable = id;
    }

    async function toggleScenarioCloneability(scenario: Scenario) {
        scenario.cloneable = !scenario.cloneable;
        await publicationListService.setScenarioCloneability(scenario.id, scenario.cloneable);

        item = scenario;
    }
</script>
<Row horizontalAlign="end">
    <ActionButton
            tooltipContent={scenario?.cloneable ? translationContext.disableScenarioClone : translationContext.enableScenarioClone}
            on:click={() => toggleScenarioCloneability(scenario)}
    >
        <span class:enabled={scenario?.cloneable} class="toggleable mdi mdi-content-duplicate"/>
    </ActionButton>
    <ActionButton
            on:click={() => openPasswordDialog(scenario?.id)}
            {disabled}
            tooltipContent={translations.pages.publication.passwordProtectionTooltip}
    >
        {#if scenario?.passwordProtected}
            <span class="mdi mdi-lock"/>
        {:else}
            <span class="mdi mdi-lock-open-outline"/>
        {/if}
    </ActionButton>
    <ActionButton
            tooltipContent={translationContext.actions.openScenario}
            on:click={() => publicationListService.openScenario(scenario?.id)}
            {disabled}
    >
        <span class="mdi mdi-open-in-new"/>
    </ActionButton>
    <!--Popup menu needs this div for proper positioning-->
    <div>
        <ActionButton
                tooltipContent={translationContext.actions.shareScenario}
                on:click={() => shareMenuVisible=true}
                {disabled}
        >
            <span class="mdi mdi-share-variant-outline"/>
        </ActionButton>
        {#if shareMenuVisible}
            <PopupMenu bind:visible={shareMenuVisible}>
                <ActionButton
                        tooltipContent={translationContext.actions.copyScenarioURI}
                        on:click={() => publicationListService.copyScenarioURIAsync(scenario?.id)}>
                    <span class="mdi mdi-link"/>
                </ActionButton>
                <ActionButton
                        tooltipContent={translationContext.actions.copyEmbedCode}
                        on:click={() => publicationListService.copyEmbedCodeAsync(scenario?.embedId)}>
                    <span class="mdi mdi-application-brackets-outline"/>
                </ActionButton>
            </PopupMenu>
        {/if}
    </div>
    <ActionButton
            tooltipContent={translationContext.actions.download}
            on:click={() => publicationListService.downloadScenario(scenario?.id)}
            {disabled}
    >
        <span class="mdi mdi-download-outline"/>
    </ActionButton>
    <ActionButton
            tooltipContent={translationContext.actions.delete}
            on:click={() => deletableScenarioObservable.set(scenario)}
            {disabled}
    >
        <span class="mdi mdi-delete-outline"/>
    </ActionButton>
</Row>
<style>
    .mdi {
        font-size: 30px;
        color: #0078d7;
    }

    .toggleable:not(.enabled) {
        color: var(--disabled-text-color);
    }
</style>
