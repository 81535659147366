﻿<script lang="ts">
    import {Button, Modal, Row} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {applicationContextKey} from 'PublicationDependencies';
    import * as Style from 'Components/Style';
    import {getContext} from 'svelte';
    import type DependencyContainer from 'Lib/DependencyContainer';
    import CustomLinkService from 'Pages/PublicationPage/Lib/Services/CustomLinkService';
    import type {CustomLink} from 'Generated/RestClient.g';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';

    const translationContext = translations.pages.publication.customLinkDeletionDialog;

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const {scenarioListObservable} = applicationContext.get(PublicationListContext);
    const customLinkService = applicationContext.get(CustomLinkService);

    export let customLink: CustomLink | undefined = undefined;

    async function deleteCustomLinkAsync(): Promise<void> {
        if (!customLink) {
            console.error('No custom link was found.');
            return;
        }

        await customLinkService.deleteCustomLink(customLink);
        customLink = undefined;
    }

    $: connectedPresentation = customLink?.presentationId 
        ? $scenarioListObservable.find(p => p.id == customLink?.presentationId) 
        : undefined;
</script>

<Modal
        isShown={customLink !== undefined}
        title={translationContext.title}>
    <svelte:fragment slot="content">
        <p>{translationContext.description.format(customLink?.name ?? "")}</p>
        {#if connectedPresentation}
            <p class="warning">{translationContext.connectedPresentationWarning.format(connectedPresentation.name)}</p>
        {/if}
    </svelte:fragment>
    <svelte:fragment slot="buttons">
        <Row horizontalAlign="end">
            <Button
                    backgroundColor={Style.colors.negative}
                    minWidth={Style.unset}
                    on:click={deleteCustomLinkAsync}>
                {translationContext.confirmAction}
            </Button>
            <Button
                    minWidth={Style.unset}
                    on:click={() => (customLink = undefined)}>
                {translationContext.cancelAction}
            </Button>
        </Row>
    </svelte:fragment>
</Modal>

<style>
    .warning {
        font-weight: bold;
    }
</style>
