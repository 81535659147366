<script lang="ts">
    import { Row } from 'Components/UI';
    import UserMenuItem from 'Menubars/Components/UserMenuItem.svelte';
</script>

<div>
    <Row verticalAlign="center">
        <UserMenuItem />
    </Row>
</div>
