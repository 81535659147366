﻿<script lang="ts">
    import ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import type {Details} from 'Pages/PublicationPage/Views/ColumnOptions';

    export let options: ColumnOptions;
    export let item: any;
    export let key: string;
    let details: Details;

    $: details = options.details!;
    $: summaryFormatted = options.formatter?.(item[key]) ?? item[key];
    $: detailsFormatted = details.formatter?.(item[details.key]) ?? item[details.key];
    $: detailsIsArray = Array.isArray(detailsFormatted);
</script>

<details>
    <summary>
        {summaryFormatted}
    </summary>
    <p>
        {#if !detailsIsArray}
            {#if details.html}
                {@html detailsFormatted}
            {:else }
                {detailsFormatted}
            {/if}
        {:else }
            {#if details.html}
                {#each detailsFormatted as line}
                    {@html line}
                {/each}
            {:else }
                {#each detailsFormatted as line}
                    {line}<br>
                {/each}
            {/if}
        {/if}
    </p>
</details>

<style>
    details {
        font-weight: normal;
        cursor: initial;
    }

    details p {
        margin: .8rem;
    }

    summary {
        cursor: pointer;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.3rem;
        white-space: nowrap;
    }
</style>
