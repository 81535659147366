<script lang="ts">
    import { onDestroy, onMount } from 'svelte';

    let dragDepth: number = 0;
    let isDragInProgress: boolean = false;

    export let onFilesDropped: ((file: FileList) => void) | undefined;

    function isValidTarget(dataTransfer: DataTransfer): boolean {
        return dataTransfer.types.length > 0 || dataTransfer.types.some(type => type === 'Files');
    }

    function dragStartListener(event: DragEvent): void {
        if (!event.dataTransfer || !isValidTarget(event.dataTransfer)) {
            return;
        }

        ++dragDepth;
        isDragInProgress = true;
    }

    function dragEndListener(): void {
        --dragDepth;
        if (dragDepth <= 0) {
            dragDepth = 0;
            isDragInProgress = false;
        }
    }

    function dragListener(event: DragEvent): void {
        if (!event.dataTransfer || !isValidTarget(event.dataTransfer)) {
            return;
        }

        event.dataTransfer.dropEffect = 'copy';
        event.preventDefault();
    }

    function dragDropListener(event: DragEvent): void {
        event.preventDefault();
        dragDepth = 0;
        isDragInProgress = false;
        if (!event.dataTransfer) return;
        onFilesDropped?.(event.dataTransfer.files);
    }

    onMount(() => {
        window.addEventListener('dragenter', dragStartListener);
        window.addEventListener('dragleave', dragEndListener);
        window.addEventListener('dragover', dragListener);
        window.addEventListener('drop', dragDropListener);
    });

    onDestroy(() => {
        window.removeEventListener('dragenter', dragStartListener);
        window.removeEventListener('dragleave', dragEndListener);
        window.removeEventListener('dragover', dragListener);
        window.removeEventListener('drop', dragDropListener);
    });
</script>

<div class:visible={isDragInProgress}>
    <slot />
</div>

<style>
    div {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0.5, 0.5, 0.5, 0.5);
        color: white;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .visible {
        display: flex;
    }
</style>
