﻿<script lang="ts">
    export let options: { value: any, label: string }[];

    export let selected = options[0].value;
</script>
<div class="root">
    <fieldset>
        {#each options as {value, label}}
            <input
                    type="radio"
                    id={label}
                    bind:group={selected}
                    value={value}/>
            <label for={label}>
                {label}
            </label>
        {/each}
    </fieldset>
</div>

<style>
    .root {
        max-width: 1500px;
        width: calc(100% - 2rem);
        display: flex;
        align-self: center;
    }

    fieldset {
        align-self: flex-start;
        background-color: rgb(244, 246, 248);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), 0 0 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: clip;
    }

    input[type='radio'] {
        display: none;
    }

    label {
        cursor: pointer;
        display: inline-block;
        padding: .8rem;
        transition: all .5s ease;
        user-select: none;
    }

    input:checked + label {
        background-color: var(--secondary-color);
        color: var(--secondary-text-color);
    }
</style>
