﻿<script lang="ts">
    import type {Order} from 'Lib/Models/Order';

    export let order: Order;
</script>

{#if order === 'asc'}
    <span class="mdi mdi-arrow-up-bold-outline"/>
{:else if order === 'desc'}
    <span class="mdi mdi-arrow-down-bold-outline"/>
{/if}
