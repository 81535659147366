<svelte:options immutable={true} />

<script lang="ts">
    import { Row, Text } from 'Components/UI';
    import translations from 'Assets/i18n';
    import 'Lib/Extensions/StringExtensions';
    import * as Style from 'Components/Style';

    export let version: string;
</script>

<div>
    <Row horizontalAlign="end">
        <Text color={Style.textColors.disabled}>{translations.global.version.format(version)}</Text>
    </Row>
</div>

<style>
    div {
        margin: 8px;
    }
</style>
