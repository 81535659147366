﻿<script lang="ts">
    import {getContext} from 'svelte';
    import {Column, Dropzone} from 'Components/UI';
    import PasswordChangeDialog from 'Pages/PublicationPage/Components/PasswordChangeDialog.svelte';
    import DeletionConfirmationDialog from 'Pages/PublicationPage/Components/DeletionConfirmationDialog.svelte';
    import {applicationContextKey} from 'PublicationDependencies';
    import DependencyContainer from 'Lib/DependencyContainer';
    import translations from 'Assets/i18n';
    import PublicationListContext from 'Pages/PublicationPage/Lib/PublicationListContext';
    import PublicationListService from 'Pages/PublicationPage/Lib/Services/PublicationListService';
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import Table from 'Pages/PublicationPage/Components/Table.svelte';
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import {dateFormatter, fileDetailsFormatter} from 'Pages/PublicationPage/Lib/Formatters';
    import PresentationButtonRow from 'Pages/PublicationPage/Views/PresentationList/PresentationButtonRow.svelte';
    import FloatingButton from 'Pages/PublicationPage/Components/FloatingButton.svelte';
    import FloatingButtonContainer from "Pages/PublicationPage/Components/FloatingButtonContainer.svelte";
    import FilterComponent from "Pages/PublicationPage/Components/FilterComponent.svelte";
    import SharedWithItem from "Pages/PublicationPage/Views/PresentationList/SharedWithItem.svelte";

    const translationContext = translations.pages.publication.presentationListView;
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);

    const {scenarioListObservable, listSortingObservable, pendingUploadCountObservable, deletableScenarioObservable} =
        applicationContext.get(PublicationListContext);
    const publicationListService = applicationContext.get(PublicationListService);
    const {scenarioIdForPasswordDialogObservable} = applicationContext.get(PublicationListContext);

    async function onFilesDroppedAsync(files: FileList): Promise<void> {
        await publicationListService.uploadScenariosAsync(files);
    }

    function sort(key: ColumnKey) {
        publicationListService.toggleSort(key);
    }

    let columns: Map<ColumnKey, ColumnOptions> = new Map<ColumnKey, ColumnOptions>([
        ['sharedWith', {
            key: 'sharedWith',
            sort: () => undefined,
            name: '',
            width: '32px',
            component: SharedWithItem,
        }],
        ['name', {
            key: 'name',
            sort,
            name: translationContext.header.itemName,
            primary: true,
            details: {key: 'files', formatter: fileDetailsFormatter}
        }],
        ['createdBy', {key: 'ownerName', sort, name: translationContext.header.createdBy}],
        ['createdAt', {key: 'createdAt', sort, name: translationContext.header.createdAt, formatter: dateFormatter}],
        ['updatedAt', {key: 'updatedAt', sort, name: translationContext.header.updatedAt, formatter: dateFormatter}],
        ['buttons', {key: 'buttons', width: '235px'}],
    ]);

    let filteredItems = [];
    let fileInput: HTMLInputElement;
    let files: FileList;
    $: if (files) {
        publicationListService.uploadScenariosAsync(files);
    }
    
    $: noItemPlaceholder = $scenarioListObservable.length === 0 ? translationContext.noContent : translationContext.noSearchMatch; 
</script>
<FloatingButtonContainer>
    <FilterComponent items={$scenarioListObservable} keys={['name']} bind:filteredItems placeholder={translations.pages.publication.actions.filterScenarios} />
    <FloatingButton
            tooltipText={translations.pages.publication.actions.upload}
            on:click={()=>fileInput?.click()}
    >
        <label class="mdi mdi-upload-outline floating-button-content">
            <input
                    accept=".actg"
                    multiple
                    type="file"
                    bind:files
                    bind:this={fileInput}
            />
        </label>
    </FloatingButton>
</FloatingButtonContainer>
<Table
        columns={columns}
        items={filteredItems}
        buttons={PresentationButtonRow}
        sortingObservable={listSortingObservable}
        pendingCount={$pendingUploadCountObservable}
        emptyPlaceholder={noItemPlaceholder}
/>
<span class="element-count">{translationContext.elementCount.format(filteredItems.length, $scenarioListObservable.length)}</span>
<PasswordChangeDialog bind:scenarioId={$scenarioIdForPasswordDialogObservable}/>
<DeletionConfirmationDialog bind:scenario={$deletableScenarioObservable}/>
<Dropzone onFilesDropped={onFilesDroppedAsync}>
    <Column
            horizontalAlign="center"
            verticalAlign="center">
        <span class="mdi mdi-upload-outline"/>
        {translations.pages.publication.dragAndDropDescription}
    </Column>
</Dropzone>

<style>
    .floating-button-content {
        font-size: 18px;
        pointer-events: none;
    }

    input[type=file] {
        display: none;
    }
     
     .element-count {
         color: #706f6f;
     }
</style>
