﻿<script lang="ts">
    import type ColumnOptions from 'Pages/PublicationPage/Views/ColumnOptions';
    import type {ColumnKey} from 'Pages/PublicationPage/Lib/ColumnKeys';
    import applyStyle from 'Lib/Utils/ApplyStyle';
    import OrderingIndicator from 'Pages/PublicationPage/Components/OrderingIndicator.svelte';
    import type {Writable} from 'svelte/store';
    import type {KeyOrderPair} from 'Lib/Models/Order';
    import SkeletonRow from 'Pages/PublicationPage/Components/Table/SkeletonRow.svelte';
    import ItemRow from 'Pages/PublicationPage/Components/Table/ItemRow.svelte';
    import type {ComponentType} from 'svelte';

    export let columns: Map<ColumnKey, ColumnOptions>;
    export let items: any[];
    export let buttons: ComponentType;
    export let sortingObservable: Writable<KeyOrderPair<ColumnKey>[]> | undefined;
    $: order = new Map($sortingObservable?.map((i) => {
        return [i.key, i.order];
    }));
    export let emptyPlaceholder: string | undefined = undefined;

    export let pendingCount = 0;
</script>
<div class="table-container">
    <table>
        <thead>
        <tr>
            {#each columns as [key, options]}
                <th use:applyStyle={{width: options.width}}
                    on:click={()=>options.sort?.(key)}
                    class:primary={options.primary} class:sortable={options.sort}>
                    {#if options.name}{options.name}{/if}
                    {#if options.sort}
                        <OrderingIndicator order={order.get(key)}/>
                    {/if}
                </th>
            {/each}
        </tr>
        </thead>
        <tbody>
        {#if pendingCount > 0 || items.length > 0 }
            {#if pendingCount > 0}
                {#each Array(pendingCount) as _}
                    <SkeletonRow {columns} {buttons}/>
                {/each}
            {/if}
            {#each items as item}
                <ItemRow {item} {columns} {buttons}/>
            {/each}
        {:else}
            <tr>
                <td colspan="{columns.size}" class="placeholder">
                    {emptyPlaceholder}
                </td>
            </tr>
        {/if}
        </tbody>
    </table>
</div>

<style>
    .table-container {
        background-color: rgb(244, 246, 248);
        margin: 0 0 0 0;
        max-width: 1500px;
        width: calc(100% - 2rem);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), 0 0 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: clip;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border-radius: 1rem;
        padding: 1rem;
    }

    .table-container th, :global(.table-container td) {
        text-align: left;
        vertical-align: middle;
        padding: 8px;
        height: 36px;
    }

    :global(.table-container td) {
        height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    :global(.table-container tr) {
        transition: all .5s ease;
    }

    :global(.table-container :not(thead) > tr:hover) {
        background-color: hsl(213, 39%, 91%);
    }

    .table-container thead {
        background-color: var(--secondary-color);
        color: var(--secondary-text-color);
        user-select: none;
    }

    .table-container th {
        white-space: nowrap;
        user-select: none;
    }

    :global(.table-container .primary) {
        width: 50%;
        font-weight: 600;
        transition: width .5s ease;
    }

    :global(.table-container .label) {
        display: none;
    }

    :global(.table-container .sortable) {
        cursor: pointer;
    }

    @media (max-width: 1200px) {
        :global(.table-container .primary) {
            width: 35%;
        }
    }

    @media (max-width: 800px) {
        :global(.table-container table) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 0;
        }

        :global(.table-container thead) {
            display: none;
        }

        :global(.table-container tr) {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            width: 100%;
            padding: .5rem;
        }

        :global(.table-container .label) {
            display: inline-block;
            width: 100px;
            font-weight: 600;
        }

        :global(.table-container td) {
            height: auto;
        }

        :global(.table-container .primary) {
            width: unset;
        }

        :global(.table-container .primary .label) {
            display: none;
        }
    }

    .placeholder {
        text-align: center;
    }
</style>
